$filled_background: get-color(primary);

.chrono {
    &__wrapper {
        position: relative;
        overflow-x: scroll;
        border: 1px solid black;
    }

    &__spacer {
        background-color: $light-gray;
        td { padding: 1px 0 !important; }
    }

    &__table {
        white-space: nowrap;



        td {
            padding: .25rem;
        }
        td.chrono {

            &__hotel, &__marken {
                font-size: 14px;
                position: sticky;
                background-color: $body-background;
            }

            &__hotel {
                position: sticky;
                min-width: 275px;
                left: -1px;
                padding: 0 5px;
                font-size: 16px;
            }

            &__marken {
                text-align: left;
                left: 273px;
                font-size: 10px;
                //min-width: ;
            }

            &__block {
                font-size: 10px;
                min-width: 32px;
                &__filled {
                    background-color: $filled_background;
                    color: color-pick-contrast($filled_background);
                }
                &__year {
                    color: $dark-gray;
                }
            }
        }

    }

    &__ref {
        list-style: none;
    }


}
