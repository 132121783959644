@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Open+Sans:ital,wght@0,400;0,700;1,400');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700');

@import "settings";
@import "foundation";

// vendor styles
// @import "vendor/aos";
// @import "vendor/slick-carousel";
// @import "vendor/index-menu";

$fa-font-path: '../webfonts';
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import '~@fortawesome/fontawesome-free/scss/regular.scss'; // https://fontawesome.com/icons?d=gallery&s=regular&m=free
@import "~@fortawesome/fontawesome-free/scss/solid.scss"; // https://fontawesome.com/icons?d=gallery&s=solid&m=free
@import '~@fortawesome/fontawesome-free/scss/brands.scss'; // https://fontawesome.com/icons?d=gallery&s=brands&m=free


html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}
header.header {
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    //background-color: rgba($noir, .75);
    /*
    position: relative;
    &:before {
        position: absolute;
        content:'';
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        pointer-events: none;
        background-image: url('../img/pxls.png');
    }
     */
    h1,h2,h3,h4,h5,h6,p {
        color: $white;//$cookie; //$body-font-color;
        text-shadow: 1px -1px 10px $black;
    }
    /*
    .header-top, .header-bottom {
        padding-top: 50px;
        p { margin: 0; }
    }
    .header-bottom {
        background-image: url('../img/header_briefmarken.jpg');
        background-size: 100% auto;
        background-position: center;
        background-attachment: fixed;
        padding-bottom: 50px;
        .shade {
            padding: 1em 0;
        }
    }
     */
    .header-inner {
        //padding-top: 75px;
        //padding-bottom: 75px;
        min-height: 40vh;
        //background-image: linear-gradient(to right, rgba($noir, .65) 0%, rgba($noir, .65) 100%), url('../img/asfalt-light.png');
        background-color: rgba($noir, .65);
        background-blend-mode: overlay;
        display: flex;
        flex-direction: column;
        justify-content: center;
        vertical-align: center;
    }
}

.gr-einheiten {
    display: grid;
    grid-template-columns: 5fr 3fr 2fr;
    grid-gap: 15px;
}

.main-navigation {
    border-bottom: 1px solid $light-gray;

    .menu > li {
        //font-family: $header-font-family;
        font-size: 1.25em;
    }
}

.wrapper {
    flex: 1 0 auto;
}

.page-content {
    padding-top: 2em;
    padding-bottom: 1em;

    h3 {
        color: $oil;
    }
    h4 {
        color: lighten(map-get($foundation-palette, primary), .35);
    }
    ul.fancy {
        list-style-image: url('../img/square.png');
        ul { list-style-image: url('../img/square-o.png'); }
    }

}

.block {
    &.block-cards {
        @include breakpoint(large up) {
            a {
                img {
                    opacity: 0.85;
                    filter: grayscale(50%);
                    transition-property: opacity, filter;
                    transition-duration: .25s;
                }

                &:hover img {
                    opacity: 1;
                    filter: grayscale(0%);
                }
            }
        }

        .card-section {
            ul {
                list-style: none;
                margin-left: 0;
                li:before {
                    content: '>';
                    margin-right: .5em;
                }
            }
        }

    }
}


.footer {
    border-top: 1px solid $light-gray;
    .copyright {
        margin: 0;
        color: $dark-gray;
    }
}

.shade {
    background-color: rgba($black, .65);
}

@import "components/chronologie";
